<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>iOS接入</h2>
      <p>
        调用 TiSDKManager 里面的相关设置函数来设置渲染参数，具体接口说明如下：
      </p>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :header-row-style="{ color: '#555' }"
      >
        <el-table-column prop="module" label="模块" width="80" align="center">
        </el-table-column>
        <el-table-column
          prop="interface"
          label="功能"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="fun" label="函数" width="360"> </el-table-column>
        <el-table-column prop="parameter" label="参数说明"> </el-table-column>
      </el-table>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>iOS access</h2>
      <p>
        Call the relevant settings function in TiSDKManager to set the rendering
        parameters, the specific interface is as follows:
      </p>
      <el-table
        :data="tableEn"
        border
        style="width: 100%"
        :header-row-style="{ color: '#555' }"
      >
        <el-table-column
          prop="module"
          label="Module"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="interface"
          label="Interface"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="fun" label="Function" width="360">
        </el-table-column>
        <el-table-column prop="parameter" label="Parameter Description">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        {
          module: "美颜",
          interface: "开关",
          fun: "setBeautyEnable:(BOOL)enable",
          parameter: "true:美颜生效;false:美颜失效",
        },
        {
          module: "美颜",
          interface: "美白",
          fun: "setSkinWhitening:(int)param",
          parameter: "美白参数，范围[0,100]，0为没有效果",
        },
        {
          module: "美颜",
          interface: "磨皮",
          fun: "setSkinBlemishRemoval:(int)param",
          parameter: "磨皮参数，范围[0,100]",
        },
        {
          module: "美颜",
          interface: "粉嫩",
          fun: "setSkinTenderness:(int)param",
          parameter: "粉嫩参数，范围[0,100]",
        },
        {
          module: "美颜",
          interface: "清晰",
          fun: "setSkinSharpness:(int)param",
          parameter: "清晰参数，范围[0,100]",
        },
        {
          module: "美颜",
          interface: "饱和",
          fun: "setSkinSaturation:(int)param",
          parameter: "饱和参数，范围[-50,50]",
        },
        {
          module: "美颜",
          interface: "亮度",
          fun: "setSkinBrightness: (int)param",
          parameter: "亮度参数，范围[-50,50]",
        },
        {
          module: "美颜",
          interface: "一键美颜",
          fun: "setOnekeyBeauty:(TiOnekeyBeautyEnum)onekeyBeautyEnum Param:(int)param;",
          parameter: "美颜参数，范围[0, 100]",
        },
        {
          module: "精准美肤",
          interface: "黑眼圈",
          fun: "setDarkCircle: (int)param",
          parameter: "黑眼圈参数，范围[0, 100]",
        },
        {
          module: "精准美肤",
          interface: "立体",
          fun: "setHighlight: (int)param",
          parameter: "立体参数，范围[0, 100]",
        },
        {
          module: "精准美肤",
          interface: "磨皮",
          fun: "setSkinPreciseBeauty: (int)param",
          parameter: "磨皮参数，范围[0, 100]",
        },
        {
          module: "精准美肤",
          interface: "粉嫩",
          fun: "setPreciseTenderness: (int)param",
          parameter: "粉嫩参数，范围[0, 100]",
        },
        {
          module: "精准美肤",
          interface: "法令纹",
          fun: "setNasolabialFold: (int)param",
          parameter: "法令纹参数，范围[0, 100]",
        },
        {
          module: "精准美肤",
          interface: "鱼尾纹",
          fun: "setCrowsFeet: (int)param",
          parameter: "鱼尾纹参数，范围[0, 100]",
        },

        {
          module: "美型",
          interface: "开关",
          fun: "setFaceTrimEnable:(BOOL)enable",
          parameter: "true:美型生效;false:美型失效",
        },
        {
          module: "美型",
          interface: "大眼",
          fun: "setEyeMagnifying:(int)param",
          parameter: "大眼参数，范围[0，100]",
        },
        {
          module: "美型",
          interface: "瘦脸",
          fun: "setChinSlimming:(int)param",
          parameter: "瘦脸参数，范围[0，100]",
        },
        {
          module: "美型",
          interface: "下巴",
          fun: "setJawTransforming:(int)param",
          parameter: "下巴参数，范围[-50，50]",
        },
        {
          module: "美型",
          interface: "额头",
          fun: "setForeheadTransforming:(int)param",
          parameter: "额头参数，范围[-50，50]",
        },
        {
          module: "美型",
          interface: "嘴型",
          fun: "setMouthTransforming:(int)param",
          parameter: "嘴型参数，范围[-50，50]",
        },
        {
          module: "美型",
          interface: "瘦鼻",
          fun: "setNoseMinifying:(int)param",
          parameter: "瘦鼻参数，范围[-50，50]",
        },
        {
          module: "美型",
          interface: "窄脸",
          fun: "setFaceNarrowing:(int)param",
          parameter: "窄脸参数，范围[0，100]",
        },
        {
          module: "美型",
          interface: "眼间距",
          fun: "setEyeSpacing:(int param)",
          parameter: "眼间距参数，范围[-50，50]",
        },
        {
          module: "美型",
          interface: "长鼻",
          fun: "setNoseElongating:(int param)",
          parameter: "长鼻参数，范围[-50，50]",
        },
        {
          module: "美型",
          interface: "眼角",
          fun: "setEyeCorners:(int param)",
          parameter: "眼角参数，范围[-50，50]",
        },
        {
          module: "美型",
          interface: "颧骨",
          fun: "setCheekboneSlimming:(int param)",
          parameter: "颧骨参数，范围[0, 100]",
        },
        {
          module: "美型",
          interface: "下颌",
          fun: "setJawboneSlimming:(int param)",
          parameter: "下颌参数，范围[0, 100]",
        },
        {
          module: "美型",
          interface: "削下巴",
          fun: "setJawSlimming:(int param)",
          parameter: "削下巴参数，范围[0, 100]",
        },
        {
          module: "美型",
          interface: "内眼角",
          fun: "setEyeInnerCorners:(int param)",
          parameter: "内眼角参数，范围[-50, 50]",
        },
        {
          module: "美型",
          interface: "外眼尾",
          fun: "setEyeOuterCorners:(int param)",
          parameter: "外眼尾参数，范围[-50, 50]",
        },
        {
          module: "美型",
          interface: "嘴角",
          fun: "setMouthHeight:(int param)",
          parameter: "嘴角参数，范围[-50, 50]",
        },
        {
          module: "美型",
          interface: "嘴唇",
          fun: "setMouthLipSize:(int param)",
          parameter: "嘴唇参数，范围[-50, 50]",
        },
        {
          module: "美型",
          interface: "扬嘴角",
          fun: "setMouthSmiling:(int param)",
          parameter: "扬嘴角参数，范围[0, 100]",
        },
        {
          module: "美型",
          interface: "眉高",
          fun: "setBrowHeight:(int param)",
          parameter: "眉高参数，范围[-50, 50]",
        },
        {
          module: "美型",
          interface: "眉长",
          fun: "setBrowLength:(int param)",
          parameter: "眉长参数，范围[-50, 50]",
        },
        {
          module: "美型",
          interface: "眉间距",
          fun: "setBrowSpace:(int param)",
          parameter: "眉间距参数，范围[-50, 50]",
        },
        {
          module: "美型",
          interface: "眉粗细",
          fun: "setBrowSize:(int param)",
          parameter: "眉粗细参数，范围[-50, 50]",
        },
        {
          module: "美型",
          interface: "眉尾",
          fun: "setBrowCorner:(int param)",
          parameter: "眉尾参数，范围[0, 100]",
        },
        {
          module: "美型",
          interface: "美牙",
          fun: "setTeethWhitening:(int param)",
          parameter: "美牙参数，范围[0, 100]",
        },
        {
          module: "美型",
          interface: "脸型",
          fun: "setFaceShape:(TiFaceShapeEnum)faceShapeNum Param:(int)param;",
          parameter: "脸型参数，范围[0, 100]",
        },
        {
          module: "贴纸",
          interface: "切换",
          fun: "setStickerName:(NSString *)stickerName",
          parameter: "切换贴纸特效，json 文件中选择",
        },
        {
          module: "互动",
          interface: "切换",
          fun: "setInteraction:(NSString *)interactionName",
          parameter: "切换互动特效参数函数，json 文件中选择",
        },
        {
          module: "滤镜",
          interface: "切换",
          fun: "setFilterEnum:(TiFilterEnum)filterEnum",
          parameter: "切换滤镜特效，TifilterEnum 中选择",
        },
        {
          module: "滤镜",
          interface: "切换",
          fun: "setFilterEnum:(TiFilterEnum)filterEnum (int)param",
          parameter: "切换滤镜特效并调节参数，范围[0, 100]",
        },
        {
          module: "礼物",
          interface: "切换",
          fun: "setGift:(NSString *)giftName",
          parameter: "切换礼物特效，json文件中选择",
        },
        {
          module: "抖动",
          interface: "切换",
          fun: "setRockEnum:(TiRockEnum)rockEnum",
          parameter: "切换抖动特效，TiRockEnum 中选择",
        },
        {
          module: "水印",
          interface: "切换",
          fun: "setWatermark:(BOOL)enable Left:(int)x Top:(int)y Ratio:(int)ratio FileName:(NSString *)fileName;",
          parameter: "切换水印特效，json 文件中选择",
        },
        {
          module: "哈哈镜",
          interface: "切换",
          fun: "setDistortionEnum: (TiDistortionEnum)distortionEnum",
          parameter: "切换哈哈镜特效，TiDistonEnum 中选择",
        },
        {
          module: "面具",
          interface: "切换",
          fun: "setMask:(NSString *)maskName",
          parameter: "切换面具特效，json 文件中选择",
        },
        {
          module: "绿幕抠图",
          interface: "切换",
          fun: "setGreenScreen:(NSString *)greenScreenName",
          parameter: "切换绿幕抠图特效，json 文件中选择",
        },
        {
          module: "人像抠图",
          interface: "切换",
          fun: "setPortrait:(NSString *)portraitName",
          parameter: "切换人像抠图特效，json 文件中选择",
        },
        {
          module: "美妆",
          interface: "开关",
          fun: "setMakeupEnable:(bool)enable",
          parameter: "美妆特效开关 true:生效;false:失效",
        },
        {
          module: "美妆",
          interface: "腮红",
          fun: "setBlusher:(NSString*)name Param:(int)param",
          parameter: "切换腮红特效，json 文件中选择",
        },
        {
          module: "美妆",
          interface: "睫毛",
          fun: "setEyeLash:(NSString*)name Param:(int)param",
          parameter: "切换睫毛特效，json 文件中选择",
        },
        {
          module: "美妆",
          interface: "眉毛",
          fun: "setEyeBrow:(NSString*)name Param:(int)param",
          parameter: "切换眉毛特效，json 文件中选择",
        },
        {
          module: "美妆",
          interface: "眼影",
          fun: "setEyeShadow:(NSString*)name Param:(int)param",
          parameter: "切换眼影特效，json 文件中选择",
        },
        {
          module: "美妆",
          interface: "眼线",
          fun: "setEyeLine:(NSString*)name Param:(int)param",
          parameter: "切换眼线特效，json 文件中选择",
        },
        {
          module: "美妆",
          interface: "美发",
          fun: "setHairEnum:(TiHairEnum)hairEnum Param:(int)param",
          parameter: "切换美发特效，TiHairEnum中选择；调节参数，范围[0, 100]",
        },
        {
          module: "美妆",
          interface: "唇彩",
          fun: "setLipGLoss:(NSString*)name Param:(int)param;",
          parameter: "切换唇彩特效，json 文件中选择",
        },

        {
          module: "手势识别",
          interface: "手势识别",
          fun: "setGesture:(NSString *)gestureName",
          parameter: "切换手势识别，json 文件中选择",
        },
        {
          module: "美体",
          interface: "开关",
          fun: "setBodyShapingEnable:(BOOL)enable;",
          parameter: "美体特效开关 true是打开；false是关闭",
        },
        {
          module: "美体",
          interface: "长腿",
          fun: "setLongLeg:(int)param Top:(int)top Bottom:(int)bottom;",
          parameter: "切换长腿特效，json 文件中选择",
        },
        {
          module: "美体",
          interface: "瘦身",
          fun: "setSlimBody:(int)param Left:(int)left Right:(int)right;",
          parameter: "切换瘦身特效，json 文件中选择",
        },
        {
          module: "其他",
          interface: "识别距离",
          fun: "setTrackDistance:(int)level;",
          parameter: "识别距离参数，默认值为1，取值范围为[0, 1, 2, 3, 4, 5, 6]",
        },
        {
          module: "其他",
          interface: "人脸数目",
          fun: "getFaceNumber;",
          parameter:
            "人脸数目参数，return -1（没有检测人脸）, 其他返回检测到的人脸数量",
        },
        {
          module: "其他",
          interface: "参数最大值限制",
          fun: "setCustomMaximumEnabled:(BOOL)enabled;",
          parameter:
            "默认开启，true:开启（即限定参数传入最大值为100）｜ false: 关闭（即用户可以根据需要设置超过默认最大值100的参数值）",
        },
      ],
      tableEn: [
        {
          module: "Beauty",
          interface: "on/off",
          fun: "setBeautyEnable:(BOOL)enable",
          parameter: "true:Beauty function on ;false:Beauty function off",
        },
        {
          module: "Beauty",
          interface: "Whitening",
          fun: "setSkinWhitening:(int)param",
          parameter:
            "Whitening parameters，Range[0,100]，0 means whitening off",
        },
        {
          module: "Beauty",
          interface: "BlemishRemoval",
          fun: "setSkinBlemishRemoval:(int)param",
          parameter: "BlemishRemoval parameters，Range[0,100]",
        },
        {
          module: "Beauty",
          interface: "Tenderness",
          fun: "setSkinTenderness:(int)param",
          parameter: "Tenderness parameters，Range[0,100]",
        },
        {
          module: "Beauty",
          interface: "Saturation",
          fun: "setSkinSharpness:(int)param",
          parameter: "Saturation parameters，Range[-50,50]",
        },
        {
          module: "Beauty",
          interface: "Brightness",
          fun: "setSkinSaturation:(int)param",
          parameter: "Brightness parameters，Range[-50,50]",
        },
        {
          module: "Beauty",
          interface: "Sharpness",
          fun: "setSkinBrightness: (int)param",
          parameter: "Sharpness parameters，Range[0,100]",
        },
        {
          module: "Beauty",
          interface: "OnekeyBeauty",
          fun: "setOnekeyBeauty:(TiOnekeyBeautyEnum)onekeyBeautyEnum Param:(int)param;",
          parameter: "OnekeyBeauty parameters，Range[0, 100]",
        },
        {
          module: "Precise beauty",
          interface: "DarkCircle",
          fun: "setDarkCircle: (int)param",
          parameter: "DarkCircle parameters，Range[0, 100]",
        },
        {
          module: "Precise beauty",
          interface: "Highlight",
          fun: "setHighlight: (int)param",
          parameter: "Highlight parameters，Range[0, 100]",
        },
        {
          module: "Precise beauty",
          interface: "PreciseBeauty",
          fun: "setSkinPreciseBeauty: (int)param",
          parameter: "SkinPreciseBeauty parameters，Range[0, 100]",
        },
        {
          module: "Precise beauty",
          interface: "PreciseTenderness",
          fun: "setPreciseTenderness: (int)param",
          parameter: "PreciseTenderness parameters，Range[0, 100]",
        },
        {
          module: "Precise beauty",
          interface: "NasolabialFold",
          fun: "setNasolabialFold: (int)param",
          parameter: "NasolabialFold parameters，Range[0, 100]",
        },
        {
          module: "Precise beauty",
          interface: "CrowsFeet",
          fun: "setCrowsFeet: (int)param",
          parameter: "CrowsFeet parameters，Range[0, 100]",
        },

        {
          module: "FaceTrim",
          interface: "on/off",
          fun: "setFaceTrimEnable:(BOOL)enable",
          parameter: "true:FaceTrim on;false:FaceTrim off",
        },
        {
          module: "FaceTrim",
          interface: "EyeMagnifying",
          fun: "setEyeMagnifying:(int)param",
          parameter: "EyeMagnifying parameters，Range[0，100]",
        },
        {
          module: "FaceTrim",
          interface: "ChinSlimming",
          fun: "setChinSlimming:(int)param",
          parameter: "ChinSlimming parameters，Range[0，100]",
        },
        {
          module: "FaceTrim",
          interface: "JawTransforming",
          fun: "setJawTransforming:(int)param",
          parameter: "JawTransforming parameters，Range[-50，50]",
        },
        {
          module: "FaceTrim",
          interface: "ForeheadTransforming",
          fun: "setForeheadTransforming:(int)param",
          parameter: "ForeheadTransforming parameters，Range[-50，50]",
        },
        {
          module: "FaceTrim",
          interface: "MouthTransforming",
          fun: "setMouthTransforming:(int)param",
          parameter: "MouthTransforming parameters，Range[-50，50]",
        },
        {
          module: "FaceTrim",
          interface: "NoseMinifying",
          fun: "setNoseMinifying:(int)param",
          parameter: "NoseMinifying parameters，Range[-50，50]",
        },
        {
          module: "FaceTrim",
          interface: "FaceTrim",
          fun: "setFaceNarrowing:(int)param",
          parameter: "FaceNarrowing parameters，Range[0，100]",
        },
        {
          module: "FaceTrim",
          interface: "EyeSpacing",
          fun: "setEyeSpacing:(int param)",
          parameter: "EyeSpacing parameters，Range[-50，50]",
        },
        {
          module: "FaceTrim",
          interface: "NoseElongating",
          fun: "setNoseElongating:(int param)",
          parameter: "NoseElongating parameters，Range[-50，50]",
        },
        {
          module: "FaceTrim",
          interface: "EyeCorners",
          fun: "setEyeCorners:(int param)",
          parameter: "EyeCorners parameters，Range[-50，50]",
        },
        {
          module: "FaceTrim",
          interface: "CheekboneSlimming",
          fun: "setCheekboneSlimming:(int param)",
          parameter: "CheekboneSlimming parameters，Range[0, 100]",
        },
        {
          module: "FaceTrim",
          interface: "Jawbone",
          fun: "setJawboneSlimming:(int param)",
          parameter: "Jawbone parameters，Range[0, 100]",
        },
        {
          module: "FaceTrim",
          interface: "JawSlimming",
          fun: "setJawSlimming:(int param)",
          parameter: "JawSlimming parameters，Range[0, 100]",
        },
        {
          module: "FaceTrim",
          interface: "InnerCorners",
          fun: "setEyeInnerCorners:(int param)",
          parameter: "InnerCorners parameters，Range[-50, 50]",
        },
        {
          module: "FaceTrim",
          interface: "OuterCorners",
          fun: "setEyeOuterCorners:(int param)",
          parameter: "OuterCorners parameters，Range[-50, 50]",
        },
        {
          module: "FaceTrim",
          interface: "MouthHeight",
          fun: "setMouthHeight:(int param)",
          parameter: "MouthHeight parameters，Range[-50, 50]",
        },
        {
          module: "FaceTrim",
          interface: "MouthLipSize",
          fun: "setMouthLipSize:(int param)",
          parameter: "MouthLipSize parameters，Range[-50, 50]",
        },
        {
          module: "FaceTrim",
          interface: "MouthSmiling",
          fun: "setMouthSmiling:(int param)",
          parameter: "MouthSmiling parameters，Range[0, 100]",
        },
        {
          module: "FaceTrim",
          interface: "BrowHeight",
          fun: "setBrowHeight:(int param)",
          parameter: "BrowHeight parameters，Range[-50, 50]",
        },
        {
          module: "FaceTrim",
          interface: "BrowLength",
          fun: "setBrowLength:(int param)",
          parameter: "BrowLength parameters，Range[-50, 50]",
        },
        {
          module: "FaceTrim",
          interface: "BrowSpace",
          fun: "setBrowSpace:(int param)",
          parameter: "BrowSpace parameters，Range[-50, 50]",
        },
        {
          module: "FaceTrim",
          interface: "BrowSize",
          fun: "setBrowSize:(int param)",
          parameter: "BrowSize parameters，Range[-50, 50]",
        },
        {
          module: "FaceTrim",
          interface: "BrowCorner",
          fun: "setBrowCorner:(int param)",
          parameter: "BrowCorner parameters，Range[0, 100]",
        },
        {
          module: "FaceTrim",
          interface: "TeethWhitening",
          fun: "setTeethWhitening:(int param)",
          parameter: "TeethWhitening parameters，Range[0, 100]",
        },
        {
          module: "FaceTrim",
          interface: "FaceShape",
          fun: "setFaceShape:(TiFaceShapeEnum)faceShapeNum Param:(int)param;",
          parameter: "FaceShape parameters，Range[0, 100]",
        },
        {
          module: "Sticker",
          interface: "switch",
          fun: "setStickerName:(NSString *)stickerName",
          parameter: "Switch sticker effect，selection in JSON file",
        },
        {
          module: "Interaction",
          interface: "switch",
          fun: "setInteraction:(NSString *)interactionName",
          parameter:
            "Toggle Interactive Special Effect Parameter Function，selection in JSON file",
        },
        {
          module: "FilterEnum",
          interface: "FilterEnum",
          fun: "setFilterEnum:(TiFilterEnum)filterEnum",
          parameter: "Switch filter effects, select in TiFilterEnum",
        },
        {
          module: "FilterEnum",
          interface: "switch",
          fun: "setFilterEnum:(TiFilterEnum)filterEnum (int)param",
          parameter:
            "Switch filter effects and adjust parameters，Range[0, 100]",
        },
        {
          module: "Gift",
          interface: "Gift",
          fun: "setGift:(NSString *)giftName",
          parameter: "Switch gift effect，selection in JSON file",
        },
        {
          module: "RockEnum",
          interface: "switch",
          fun: "setRockEnum:(TiRockEnum)rockEnum",
          parameter: "Switch rock effects, select in TiRockEnum",
        },
        {
          module: "Watermar",
          interface: "switch",
          fun: "setWatermarkName: (NSString *)warternameName",
          parameter: "Switch Watermar effects，selection in JSON file",
        },
        {
          module: "DistortionEnum",
          interface: "switch",
          fun: "setDistortionEnum: (TiDistortionEnum)distortionEnum",
          parameter: "Switch Distortion effects, select in TiDistortionEnum",
        },
        {
          module: "Mask",
          interface: "switch",
          fun: "setMask:(NSString *)maskName",
          parameter: "Switch Mask effects，selection in JSON file",
        },
        {
          module: "GreenScreen",
          interface: "GreenScreen",
          fun: "setGreenScreen:(NSString *)greenScreenName",
          parameter: "Switch GreenScreen effects，selection in JSON file",
        },
        {
          module: "Portrait",
          interface: "Switch",
          fun: "setPortrait:(NSString *)portraitName",
          parameter: "choose from json file to switch portrait",
        },
        {
          module: "Makeup",
          interface: "On/Off",
          fun: "setMakeupEnable:(bool)enable",
          parameter: "true:take effect;false:no effect",
        },
        {
          module: "Makeup",
          interface: "Blusher",
          fun: "setBlusher:(NSString*)name Param:(int)param",
          parameter: "choose from json file to switch blusher",
        },
        {
          module: "Makeup",
          interface: "EyeLash",
          fun: "setEyeLash:(NSString*)name Param:(int)param",
          parameter: "choose from json file to switch eyelash",
        },
        {
          module: "Makeup",
          interface: "EyeBrow",
          fun: "setEyeBrow:(NSString*)name Param:(int)param",
          parameter: "choose from json file to switch eyebrow",
        },
        {
          module: "Makeup",
          interface: "EyeShadow",
          fun: "setEyeShadow:(NSString*)name Param:(int)param",
          parameter: "choose from json file to switch eyeshadow",
        },
        {
          module: "Makeup",
          interface: "EyeLine",
          fun: "setEyeLine:(NSString*)name Param:(int)param",
          parameter: "choose from json file to switch eyeline",
        },
        {
          module: "Makeup",
          interface: "Hair",
          fun: "setHairEnum:(TiHairEnum)hairEnum Param:(int)param",
          parameter: "switch hair and change parameter",
        },
        {
          module: "Makeup",
          interface: "LipGLoss",
          fun: "setLipGLoss:(NSString*)name Param:(int)param;",
          parameter: "switch lip gloss effects, select in json file",
        },

        {
          module: "Gesture",
          interface: "Gesture",
          fun: "setGesture:(NSString *)gestureName",
          parameter: "Switch gesture recognition, select in json file",
        },
        {
          module: "Body",
          interface: "switch",
          fun: "setBodyShapingEnable:(BOOL)enable;",
          parameter: "Body special effects switch true is on; false is off",
        },
        {
          module: "Body",
          interface: "LongLeg",
          fun: "setLongLeg:(int)param Top:(int)top Bottom:(int)bottom;",
          parameter: "Switch long leg effects, select from json file",
        },
        {
          module: "Body",
          interface: "SlimBody",
          fun: "setSlimBody:(int)param Left:(int)left Right:(int)right;",
          parameter: "Switch slimming effects, select from json file",
        },
        {
          module: "other",
          interface: "TrackDistance",
          fun: "setTrackDistance:(int)level;",
          parameter:
            "Identification distance parameter, the default value is 1, the value range is [0, 1, 2, 3, 4, 5, 6]",
        },
        {
          module: "other",
          interface: "FaceNumber",
          fun: "getFaceNumber;",
          parameter:
            "The number of faces parameter, return -1 (no face detected), others return the number of detected faces",
        },
        {
          module: "other",
          interface: "CustomMaximum",
          fun: "setCustomMaximumEnabled:(BOOL)enabled;",
          parameter:
            "The default open, true: enabled (the maximum value of the input parameter is 100) | false: disabled (the user can set a parameter value that exceeds the default maximum value of 100 according to needs)",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
</style>